import React, { useState, useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { Button, HTML, PageHeader, Image } from "../components";

import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

const TestimonialsFeatures = (expanded, setExpanded) => {
  return ({ company, companyLogo, clientName, body }, i) => (
    <div key={i}>
      <div className="w-11/12 mx-4 mb-4 lg:mb-0 border border-solid border-primary gap-4 flex items-center gap-3 p-4">
        <img
          className="w-28 h-28 object-contain mt-1 pr-8 filter grayscale"
          src={companyLogo.sourceURL}
        />
        <div>
          {/* <HTML className="w-full mt-4 my-4 text-md text-primary" html={body} /> */}
          {expanded === i ? (
            <HTML
              className="w-full mt-4 my-4 text-md text-primary"
              html={body}
            />
          ) : (
            <div
              className={`markdown-html ${body.className} w-full mt-4 my-4 text-md text-primary`}
              dangerouslySetInnerHTML={{
                __html: body.substring(0, 90) + "...",
              }}
            />
          )}

          <div
            className="flex flex-row text-xs cursor-pointer text-primary hover:underline relative "
            onClick={() => setExpanded(expanded === i ? null : i)}
            onKeyPress={(e) =>
              e.key === " " && setExpanded(expanded === i ? null : i)
            }
            role="button"
            aria-label={expanded === i ? "Read Less" : "Read More"}
            tabIndex={0}
          >
            <div className="md:absolute -top-5 right-5">
              {expanded === i ? "Read Less" : "Read More"}
            </div>
          </div>

          <div className="w-full font-bold text-sm text-primary">
            {company}, <br />
            {clientName}
          </div>
        </div>
      </div>
    </div>
  );
};

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="text-primary"
      style={{
        fontSize: "100px",
        position: "absolute",
        top: "20%",
        left: "-5%",
      }}
      onClick={onClick}
    >
      <FaAngleLeft />
    </div>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className="text-primary"
      style={{
        fontSize: "100px",
        position: "absolute",
        top: "20%",
        right: "-5%",
      }}
      onClick={onClick}
    >
      <FaAngleRight />
    </div>
  );
}

const TestimonialSlider = ({ items }) => {
  let [expanded, setExpanded] = useState(null);
  const sliderRef = useRef(null);
  const [sliderSettings, setSliderSettings] = useState({
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
        },
      },
    ],
  });

  useEffect(() => {
    const slider = sliderRef.current;

    // initialize slider with initial settings

    if (slider) {
      slider.slickGoTo(0);
      // slider.slickSetOption(sliderSettings);
    }

    // update slider settings on window resize
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 992) {
        setSliderSettings({
          slidesToShow: 3,
          slidesToScroll: 1,
          adaptiveHeight: false,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                adaptiveHeight: true,
              },
            },
          ],
        });
      } else {
        setSliderSettings({
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        });
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-11/12 mx-auto">
      <Slider ref={sliderRef} {...sliderSettings}>
        {items.map(TestimonialsFeatures(expanded, setExpanded))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
