import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import { Header, Footer, CookieConsent } from './';

import Logo from '../images/full-logo.png';

const isBrowser = typeof window !== 'undefined';

const Layout = ({
    title = null,
    header = true,
    footer = true,
    children
}) => {
    const [ showCookieConsent, setShowCookieConsent ] = useState(true);

    const cookies = isBrowser && document.cookie.split('; ');
    const gaCookie = cookies && cookies.find(c => c.startsWith('gatsby-gdpr-google-analytics='));
    const liCookie = cookies && cookies.find(c => c.startsWith('gatsby-gdpr-linkedin='));

    return (
        <>
            <Helmet
                titleTemplate="Integrum ESG | %s"
                defaultTitle="Integrum ESG"
            >
                { title !== null && <title itemProp='name' lang='en'>{ title }</title> }

                <meta name='keywords' content="esg, esg investing, impact investing, environmental, social, governance, sentiment, api"/>
                <meta name='description' content={ metaDescription }/>
                <meta name='viewport' content="width=device-width, initial-scale=1.0"/>

                <meta property='og:title' content="Integrum ESG"/>
                <meta property='og:type' content='website'/>
                <meta property='og:description' content={ metaDescription }/>
                <meta property='og:image' content={ Logo }/>

                <html lang='en' amp/>
            </Helmet>
            { header && <Header/> }
            <main id='scroll-container' className='scroll-container flex flex-col absolute bottom-0 top-16 lg:top-32 w-full'>
                { children }
                { footer && <Footer/> }
            </main>
            { showCookieConsent && (gaCookie === undefined || liCookie === undefined) && <CookieConsent hide={ () => setShowCookieConsent(false) }/> }
        </>
    );
};

const metaDescription = "Integrum ESG offers a one-stop solution for assets managers and owners looking to integrate Environmental, Social and Governance data into their research.";

export default Layout;