import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Button, Modal } from './';

const GOOGLE_RECAPTCHA_API_KEY = '6LfntPgaAAAAAKXZA6uAZADfANjIbkuYQ3NBd3UR';

const isBrowser = typeof window !== 'undefined';

const DemoButton = ({
    className = '',
    headerButton = false
}) => {
    const [ showModal, setShowModal ] = useState(false);
    const [ formData, setFormData ] = useState({  });
    const [ status, setStatus ] = useState(null);

    const recaptchaRef = useRef(null);

    useEffect(() => {
        if (isBrowser && headerButton && window.location.hash === '#request-a-demo') {
            setShowModal(true);
        }
    }, [  ]);

    const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handleSubmit = async e => {
        setStatus(null);
        e.preventDefault();

        try {
            const res = await fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                    'form-name': e.target.getAttribute('name'),
                    'g-recaptcha-response': recaptchaRef.current.getValue(),
                    ...formData,
                }),
            });

            if (res.ok) {
                setStatus('success');
                setFormData({  });
            } else throw res;
        } catch (err) {
            setStatus('error');
            console.log(err);
        }
    };

    return (
        <>
            <Button text="Request a Demo" className={ `bg-highlight text-dark ${className}` } action={ () => setShowModal(true) }/>
            <div hidden={ !showModal }>
                <Modal
                    closeModal={ () => {
                        setShowModal(false);
                        if (isBrowser) window.history.pushState('', document.title, window.location.pathname);
                    } }
                    large
                >
                    <div className='mt-4 mb-8 text-center text-3xl lg:text-5xl text-primary'>Request Your Demo</div>
                    <form
                        className='mb-8'
                        name='request-a-demo'
                        data-netlify='true'
                        data-netlify-recaptcha='true'
                        onSubmit={ handleSubmit }
                    >
                        <input type='hidden' name='form-name' value='request-a-demo'/>
                        <div className='mb-4'>
                            <label className='text-primary block mb-1' htmlFor='name'>Name *</label>
                            <input className='demo-input' type='text' name='name' onChange={ handleChange } required/>
                        </div>
                        <div className='mb-4'>
                            <label className='text-primary block mb-1' htmlFor='email'>Email *</label>
                            <input className='demo-input' type='email' name='email' onChange={ handleChange } required/>
                        </div>
                        <div className='mb-4'>
                            <label className='text-primary block mb-1' htmlFor='company'>Company *</label>
                            <input className='demo-input' type='text' name='company' onChange={ handleChange } required/>
                        </div>
                        <div className='mb-4'>
                            <label className='text-primary block mb-1' htmlFor='position'>Position</label>
                            <input className='demo-input' type='text' name='position' onChange={ handleChange }/>
                        </div>
                        <div className='mb-4'>
                            <label className='text-primary block mb-1' htmlFor='phone'>Phone</label>
                            <input className='demo-input' type='tel' name='phone' onChange={ handleChange }/>
                        </div>
                        <div className='mb-4 flex flex-row justify-center'>
                            <ReCAPTCHA ref={ recaptchaRef } sitekey={ GOOGLE_RECAPTCHA_API_KEY }/>
                        </div>
                        { status === 'success' ? (
                            <div className='mb-4 py-2 px-4 text-justify text-lg text-green-700 bg-light'>
                                Your request for a demo has been successfully received.  We will be in touch shortly to organise a suitable time.
                                Thank you for your interest!
                            </div>
                        ) : status === 'error' && (
                            <div className='mb-4 py-2 px-4 text-justify text-lg text-red-700 bg-light'>
                                An error has occurred trying to process your request.  Please try to get in touch with us either directly via email
                                or through our phone number.
                            </div>
                        ) }
                        <div className='flex flex-row justify-center'>
                            <button className='button bg-highlight text-dark' type='submit'>Submit</button>
                        </div>
                    </form>
                    <div className='text-justify text-sm text-gray-500'>
                        Integrumesg.com needs the contact information you provide us to contact you about our products and services.
                        For information on our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.
                    </div>
                </Modal>
            </div>
        </>
    );
};

const encode = data => Object.entries(data)
    .map(([ key, value ]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

export default DemoButton;