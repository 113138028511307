import React from 'react';

import { Image } from './';

const PageHeader = ({
    text = null,
    colour = 'text-white',
    background = 'bg-black',
    height = 'h-header-1/6 lg:h-header-2/6',
    image = null,
    imageClassName = 'w-full',
    cutoutColour = 'bg-white',
    children,
    className = ''
}) => {
    return (
        <div className={ `${height} ${background} ${className}` }>
            <div className='w-full h-full overflow-hidden'>
                { image !== null && <Image image={ image } className={ imageClassName } alt="Page header image"/> }
            </div>
            <div className={ `page-header-cutout w-full h-full ${cutoutColour} z-10 relative -top-full mt-px` }>
                { children }
            </div>
            { text !== null && (
                <div className='h-full w-full 2xl:w-1/2 relative -top-2full flex 2xl:justify-center 2xl:items-center'>
                    <div className={ `px-4 pt-4 sm:px-6 sm:pt-6 lg:px-10 lg:pt-10 2xl:px-0 2xl:pt-0 2xl:pb-16 ${colour} text-3xl sm:text-4xl lg:text-6xl font-medium z-20` } style={ { textShadow: "1px 1px 2px black" } }>{ text }</div>
                </div>
            ) }
        </div>
    );
};

export default PageHeader;