import React from 'react';
import { FaTimes } from 'react-icons/fa';

import { Icon } from './';

const Modal = ({
    closeModal,
    large = false,
    noWidth = false,
    background = 'bg-white',
    padding = 'p-8 lg:px-16',
    children
}) => (
    <div
        className={ `flex items-start ${large ? '' : 'lg:items-center'} p-4 overflow-auto fixed top-0 left-0 bottom-0 right-0 z-50 bg-gray-600 bg-opacity-60` }
        onClick={ e => e.target === e.currentTarget && closeModal() }
        onKeyPress={ e => (e.key === 'Esc' || e.key === 'Escape') && closeModal() }
        role='button'
        aria-label="Close modal (background)"
        tabIndex={ -1 }
    >
        <div className={ `max-w-full ${padding} mx-auto ${noWidth ? '' : 'w-full md:w-4/5 lg:w-3/5 xl:w-2/5 3xl:w-2/6'} ${background} rounded-xl border border-primary relative cursor-default` }>
            <Icon
                className='absolute top-0 right-0 mr-4 mt-4 cursor-pointer'
                iconClassName='text-3xl text-primary hover:text-red-700'
                onClick={ closeModal }
                onKeyPress={ e => e.key === ' ' && closeModal() }
                role='button'
                aria-label="Close modal (X)"
                tabIndex={ 0 }
            >
                <FaTimes/>
            </Icon>
            { children }
        </div>
    </div>
);

export default Modal;