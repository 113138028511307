import React, { useState } from "react";
import { Link } from "gatsby";
import { FaRegPlayCircle, FaLink } from "react-icons/fa";

import { Image, Icon, Modal } from "./";

const VideoThumbnail = ({
  className = "",
  playButtonColour = "white",
  title = "Video",
  thumbnail,
  size,
  video,
  videoLink = null,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        className={`w-full relative border-2 border-primary cursor-pointer ${className}`}
        onClick={() => setShowModal(true)}
        onKeyPress={(e) => e.key === " " && setShowModal(true)}
        role="button"
        aria-label="Play video"
        tabIndex={0}
      >
        <Image
          image={thumbnail}
          aspectRatio={1.7}
          size={size}
          alt={`Thumbnail for video '${title}'. Click to play.`}
        />
        <div className="flex justify-center items-center absolute top-0 left-0 bottom-0 right-0 transition-all hover:opacity-50 hover:bg-gray-400 hover:bg-opacity-30">
          <Icon iconClassName={`text-5xl text-${playButtonColour}`}>
            <FaRegPlayCircle />
          </Icon>
        </div>
      </div>
      {showModal && (
        <Modal
          closeModal={() => setShowModal(false)}
          large
          noWidth
          background="bg-black"
          padding="pb-8 pt-12"
        >
          <div className="flex justify-center items-center px-4 mb-4 text-white">
            <div className="text-2xl lg:text-4xl font-light mr-4 lg:mr-8">
              {title}
            </div>
            {videoLink !== null && (
              <Link to={videoLink}>
                <Icon iconClassName="text-base lg:text-xl hover:text-secondary">
                  <FaLink />
                </Icon>
              </Link>
            )}
          </div>
          <video src={video.sourceURL} title={title} controls autoPlay />
        </Modal>
      )}
    </>
  );
};

export default VideoThumbnail;
