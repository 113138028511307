import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazy-load';

const Image = ({
    image,
    aspectRatio = null,
    alt = "Image",
    lazy = true,
    className = '',
    size = 'full-width',
    objectFit = 'cover',
    background = 'none',
    ...rest
}) => {
    if (!!image && typeof image === 'string') return (
        <div className={ `overflow-hidden ${className}` }>
            <img src={ image } alt={ alt } className={ `w-full h-full object-${objectFit}` }/>
        </div>
    );
    
    if (!!image && image.cloudinaryMedia) {
        const { assetType, deliveryType, urlRemainder } = image;

        if (assetType === 'video') {
            const source = `https://res.cloudinary.com/integrum-esg/${assetType}/${deliveryType}/${size === 'full-width' ? 'q_auto:eco' : 'q_auto,c_limit,h_720,w_1280'}/${urlRemainder}`;
            return (
                <div className={ `overflow-hidden ${className}` }>
                    <video className='w-full h-full' src={ source } muted disableRemotePlayback autoPlay playsInline loop title={ alt }/>
                </div>
            );
        }

        const ar = aspectRatio !== null ? `,c_crop,ar_${aspectRatio},g_center` : "";
        const transformations = `f_auto,q_auto${ar}`;
        const source = `https://res.cloudinary.com/integrum-esg/${assetType}/${deliveryType}/${transformations}/${urlRemainder}`;
        const smallSource = `https://res.cloudinary.com/integrum-esg/${assetType}/${deliveryType}/${transformations}/c_fit,h_20,w_20/${urlRemainder}`;
        const sourceSet = imageSizes.map(s => `https://res.cloudinary.com/integrum-esg/${assetType}/${deliveryType}/${transformations}/c_fit,h_${s},w_${s}/${urlRemainder} ${s}w`);
        
        return (
            <div className={ `overflow-hidden ${className}` }>
                { lazy ? (
                    <div className='relative w-full h-full'>
                        <img src={ smallSource } alt={ alt } className={ `w-full h-full mx-auto object-${objectFit}` }/>
                        <LazyLoad className={ `w-full h-full absolute top-0 bg-${background}` } offset={ 100 }>
                            <img
                                src={ source }
                                srcSet={ sourceSet }
                                sizes={ sizes[size] }
                                alt={ alt }
                                className={ `w-full h-full mx-auto object-${objectFit}` }
                            />
                        </LazyLoad>
                    </div>
                ) : (
                    <img
                        src={ source }
                        srcSet={ sourceSet }
                        sizes={ sizes[size] }
                        alt={ alt }
                        className={ `w-full h-full mx-auto object-${objectFit}` }
                    />
                ) }
            </div>
        );
    }

    if (!!image && getImage(image)) return <GatsbyImage image={ getImage(image) } className={ className } alt={ alt } { ...rest }/>;

    return null;
};

const imageSizes = [
    '320',
    '450',
    '640',
    '750',
    '800',
    '900',
    '1000',
    '1200',
    '1500',
    '1600',
    '2000'
];

const sizes = {
    'full-width': "100vw",
    'large': "(min-width: 1536px) 60vw, (min-width: 1024px) 80vw, 100vw",
    'medium': "(min-width: 1536px) 40vw, (min-width: 1024px) 60vw, 100vw",
    'small': "(min-width: 1536px) 20vw, (min-width: 1024px) 40vw, 100vw",
    'tiny': "(min-width: 1536px) 10vw, (min-width: 1024px) 20vw, 100vw"
};

export default Image;