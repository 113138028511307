import React, { useState, useRef } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { FaBars, FaTimes, FaAngleUp, FaAngleDown } from "react-icons/fa";

import { DemoButton, Icon } from "./";

import LogoSVG from "../images/logo.svg";

const isBrowser = typeof window !== "undefined";

const MenuItem = ({ name, page, dropdownItems = null }, i) => {
  const [hovering, setHovering] = useState(false);
  const mouseLeaveRef = useRef(null);

  const mouseEnter = () => {
    setHovering(true);
    clearTimeout(mouseLeaveRef.current);
  };

  const mouseLeave = () =>
    (mouseLeaveRef.current = setTimeout(() => setHovering(false), 500));

  const selected =
    isBrowser &&
    (window.location.pathname === page ||
      (dropdownItems !== null &&
        dropdownItems.reduce(
          (acc, cur) => acc || window.location.pathname === cur.page,
          false
        )));

  return (
    <div key={i} className="relative" role="menuitem">
      <Link
        to={page}
        onKeyPress={(e) => e.key === " " && setHovering(!hovering)}
      >
        <div
          className={`menu-item ${
            selected
              ? "text-secondary border-b-2 border-secondary -mb-px"
              : "text-white border-b border-white"
          }`}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          role="button"
          aria-label={`Hover capture for menu item ${name}`}
          tabIndex={-1}
        >
          <div
            className={`flex text-center text-lg xl:text-xl font-light select-none${
              selected ? " -mb-px" : ""
            }`}
          >
            {name}
          </div>
        </div>
      </Link>
      {dropdownItems !== null && hovering && (
        <div
          className="w-2full z-30 p-4 absolute -left-1/2 mt-4 border border-primary rounded-lg bg-light shadow-2xl"
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          role="menu"
          aria-label={`Hover capture for menu item ${name}`}
          tabIndex={-1}
        >
          {dropdownItems.map(({ name, page }, j) => (
            <Link key={j} to={page}>
              <div
                className={`w-full py-4 text-center text-xl ${
                  isBrowser && window.location.pathname === page
                    ? "text-secondary"
                    : "text-primary"
                } hover:text-secondary font-light select-none`}
                role="menuitem"
              >
                {name}
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const SideMenuItem = ({ name, page, dropdownItems = null }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const linkWrapper = (...children) =>
    dropdownItems === null ? (
      <Link to={page}>{children}</Link>
    ) : (
      <div
        className="flex cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
        onKeyPress={(e) => e.key === " " && setShowDropdown(!showDropdown)}
        role="button"
        aria-label={`Open dropdown menu for ${name}`}
        tabIndex={0}
      >
        {children}
      </div>
    );

  const selected =
    isBrowser &&
    (window.location.pathname === page ||
      (dropdownItems !== null &&
        dropdownItems.reduce(
          (acc, cur) => acc || window.location.pathname === cur.page,
          false
        )));

  return (
    <>
      {linkWrapper(
        <div
          className={`side-menu-item ${
            selected
              ? "text-secondary border-l-2 -ml-px border-secondary"
              : "text-white border-l border-white"
          }`}
          role="menuitem"
        >
          {name}
        </div>
      )}
      {showDropdown && (
        <div className="ml-4" role="menu">
          {dropdownItems.map((props, i) => (
            <SideMenuItem key={i} {...props} />
          ))}
        </div>
      )}
    </>
  );
};

const Header = () => {
  const [showSideMenu, setShowSideMenu] = useState(false);

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query MenuItemsQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "menu-item" } } }
        sort: { fields: frontmatter___sortOrder }
      ) {
        edges {
          node {
            frontmatter {
              name
              page
              dropdownItems {
                name
                page
              }
            }
          }
        }
      }
    }
  `);

  const menu = allMarkdownRemark.edges.map(({ node }) => node.frontmatter);

  return (
    <>
      <div className="h-16 lg:h-32 w-full absolute z-40 flex justify-between lg:grid lg:grid-cols-15 lg:gap-4 2xl:gap-0 bg-primary p-3 lg:py-6 lg:px-6 xl:px-12 shadow-2xl">
        <div className="lg:py-2 lg:col-span-5 xl:col-span-4 select-none">
          <Link to="/" className="flex items-center">
            <div
              className="h-10 w-10 lg:h-16 lg:w-16 square-logo-div"
              style={{ backgroundImage: `url(${LogoSVG})` }}
            />
            <div className="text-2xl lg:text-3xl ml-2 lg:ml-4 text-secondary">
              INTEGRUM ESG
            </div>
          </Link>
        </div>
        <div
          className={`
          w-full py-2 hidden lg:col-span-8 xl:col-span-9 
          ${false && `lg:grid grid-cols-${menu.length}`}
          lg:flex justify-end gap-10 items-center `}
          role="menu"
        >
          {menu.map(MenuItem)}
        </div>
        <div className="flex overflow-hidden w-0 lg:w-auto lg:col-span-2 justify-self-end items-center">
          <DemoButton
            className="header-demo-button"
            headerButton={!showSideMenu}
          />
        </div>
        <Icon
          className="flex lg:hidden items-center cursor-pointer"
          iconClassName="text-white text-2xl"
          onClick={() => setShowSideMenu(true)}
          role="button"
          aria-label="Open side menu"
          tabIndex={0}
        >
          <FaBars />
        </Icon>
      </div>
      {showSideMenu && (
        <div
          className="side-menu w-4/5 md:w-3/5 p-3 pl-4 z-40 fixed right-0 top-0 bottom-0 bg-primary border-l border-secondary shadow-2xl"
          role="menu"
        >
          <div className="w-full mb-4 flex justify-between">
            <div className="flex items-center">
              <DemoButton />
            </div>
            <Icon
              className="h-10 flex items-center cursor-pointer"
              iconClassName="text-white text-3xl"
              onClick={() => setShowSideMenu(false)}
              role="button"
              aria-label="Close side menu"
              tabIndex={0}
            >
              <FaTimes />
            </Icon>
          </div>
          {menu.map((props, i) => (
            <SideMenuItem key={i} {...props} />
          ))}
        </div>
      )}
    </>
  );
};

export default Header;
