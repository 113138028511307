import React from 'react';
import { IconContext } from 'react-icons';

const Icon = ({ icon = null, children, className = '', iconClassName = '', ...rest }) => {
    if (icon !== null) {
        const { attr, children } = icon.props;
        Object.keys(attr).forEach(key => { if (attr[key] === '') delete attr[key]; });
        return (
            <div className={ className } { ...rest }>
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    { ...attr }
                    className={ iconClassName }
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    { children.map(({ key, type, props }) => {
                        Object.keys(props).forEach(key => { if (props[key] === '') delete props[key]; });
                        return React.createElement(type, { key, ...props })
                    }) }
                </svg>
            </div>
        );
    }

    return (
        <IconContext.Provider value={ { className: iconClassName } }>
            <div className={ className } { ...rest }>
                { children }
            </div>
        </IconContext.Provider>
    );
};

export default Icon;