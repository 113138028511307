import React from "react";

const Button = ({
  text,
  action = () => null,
  className = "bg-secondary text-white mx-4",
  ...rest
}) => (
  <div
    className={`button ${className}`}
    onClick={action}
    onKeyPress={(e) => e.key === " " && action()}
    role="button"
    tabIndex={0}
    {...rest}
  >
    {text}
  </div>
);

export default Button;
