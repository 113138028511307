import React from 'react';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { initialiseAndTrackLinkedIn } from '../../plugins/gatsby-plugin-gdpr-cookies-linkedin';

import { Button } from './';

const CookieConsent = ({ hide }) => {
    const location = useLocation();

    const setConsent = value => {
        document.cookie = `gatsby-gdpr-google-analytics=${value};path=/;max-age=60*60*24*365`;
        document.cookie = `gatsby-gdpr-linkedin=${value};path=/;max-age=60*60*24*365`;
        if (value) {
            initializeAndTrack(location);
            initialiseAndTrackLinkedIn(location);
        }
        hide();
    };

    return (
        <div className='w-full py-4 sm:py-8 lg:py-16 fixed bottom-0 left-0 bg-gray-300 z-50 border-t border-black'>
            <div className='w-full px-4 sm:px-8 md:px-0 md:w-4/5 lg:w-3/5 3xl:w-2/5 mx-auto'>
                <div className='text-lg text-justify mb-4'>
                    We use cookies for our analytics to help us understand how many visitors we get to this website and its pages!
                    You are free to opt out of these tracking cookies in accordance with GDPR.
                </div>
                <div className='flex flex-col sm:flex-row justify-between items-center'>
                    <div className='text-lg mb-4 sm:mb-0 sm:mr-4'>Are you OK with us storing cookies?</div>
                    <div className='flex justify-center'>
                        <Button text="Yes" className='bg-secondary text-white mr-4' action={ () => setConsent(true) }/>
                        <Button text="No" className='bg-red-700 text-white' action={ () => setConsent(false) }/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;